import { useQuery } from "@tanstack/react-query";
import {
  getAdminWorkflow,
  getGenericWorkflowStatus,
  getUserWorkflows,
  getWorkflowById,
  getWorkflows,
} from "../../sevices/apiWorkflows";

export function useWorkflow() {
  const {
    isLoading,
    data: workflows,
    error,
    isError,
  } = useQuery({
    queryKey: ["workflows"],
    queryFn: getWorkflows,
  });

  return { isLoading, error, workflows, isError };
}

export function useWorkflowById(id, board_id) {
  const { isLoading, data, error, isError } = useQuery({
    queryKey: [`workflow`, board_id],
    queryFn: () => getWorkflowById(id, board_id),
  });

  return { isLoading, error, data, isError };
}

export function useAdminWorkflow() {
  const {
    isLoading,
    data: adminWorkflows,
    error,
    isError,
  } = useQuery({
    queryKey: ["adminWorkflow"],
    queryFn: getAdminWorkflow,
  });

  return { isLoading, error, adminWorkflows, isError };
}

export function useUserWorkflowList() {
  const {
    isLoading,
    data: userWorkflows,
    error,
    isError,
  } = useQuery({
    queryKey: ["userWorkflowList"],
    queryFn: getUserWorkflows,
  });

  return { isLoading, error, userWorkflows, isError };
}

export function useGenericWorkflowStatus() {
  const {
    isLoading,
    data: genericWorkflowStatus,
    error,
    isError,
  } = useQuery({
    queryKey: ["getGenericWorkflowStatus"],
    queryFn: getGenericWorkflowStatus,
  });

  return { isLoading, error, genericWorkflowStatus, isError };
}

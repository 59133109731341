import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import './edit-text.scss'
import { updateCard } from "../../../../../../sevices/apiCards";
import { useBoardName } from "../../../../../../utils/reactQuery/boards";
import { currentUserId, isAdmin } from "../../../../../../utils/constants";
// import { isAdmin } from "../../../../../utils/constants";
// import { updateBoard } from "../../../../../sevices/apiBoards";

function EditTitle({ title,cardId, data }) {
  const { id } = useParams();
  const { boardName } = useBoardName(id);
  const boardOwnerId = boardName?.data?.data?.responseData?.owner_user_id
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);


  const schema = yup.object().shape({
    title: yup
      .string()
      // .required("Title is required")
      .max(255, "Character limit exceeded! Maximum 255 characters allowed."),
  });

  // React Hook Form
  const { register, handleSubmit, reset, getValues, formState, setValue } =
    useForm({
      defaultValues: {
        title: title,
      },
      resolver: yupResolver(schema),
    });
  const { errors } = formState;

  useEffect(() => {
    setValue("title", title);
  }, [cardId,title]);

  const { mutate } = useMutation({
    mutationFn: updateCard,
    onSuccess: (res) => {
      //   queryClient.invalidateQueries(["boards"]);
        queryClient.invalidateQueries({
          queryKey: ["card", cardId],
        });
        queryClient.invalidateQueries({
          queryKey: ["cards", id],
        });
      setLoading(false);
      toast.success(res.data.message);
      //  handleCloseDialog();
    },
    onError: (error) => {
      console.error("Error Update card title", error);
      toast.error(error.response.data.message);
      setLoading(false);
      //  handleCloseDialog();
    },
  });

  const detailTitle = title ? (title.charAt(0).toUpperCase() + title.slice(1)) : '';

  const submit = (data) => {
    /**
     * Change Board Name
     */

    setOpen(false);
    // if (data.board_name != boardName.data.responseData.board_name) {
      setLoading(true);
      if(data.title != title)
      mutate({
      card_id: cardId,
      data,
    });
    // }
  };

  const openField = () => {
    // if (boardName?.data?.responseData?.is_owner || isAdmin())
    if(isAdmin() || boardOwnerId == currentUserId() || data?.reporter == currentUserId())   
    {
      // console.log("title",title)
      setOpen(true);
    }
  
  };

  console.log("getValues", formState);
  return (
    <>
      {open ? (
        <>
          <Box
            component="form"
            onSubmit={handleSubmit(submit)}
            noValidate
            className="board-field-name"
            onBlur={handleSubmit(submit)}
          >
            <TextField
              // label={"Board Name"}
              sx={{ height: "1.375em" }}
              required
              fullWidth
              size="small"
              id="title"
              name="title"
              error={errors.title}
              // error={!errors?.title ? true : false}
              helperText={errors?.title && errors.title.message}
              {...register("title")}
              autoFocus
              // className="edit-text"
            />
          </Box>
        </>
      ) : (
        <p className="edit-text" onClick={openField}>
          {detailTitle}
        </p>
      )}
    </>
  );
}

export default EditTitle;

import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import BasicTextFields from "./addComment/addComment";
import AutoGridNoWrap from "./showComments/showComment";
import ShowWorklog from "../../worklog/showWorklog";
import "./detailGrid.scss";
import Description from "./description/description";
import SidebarGrid from "../sidebar/sidebar";
import AttachmentCard from "../showDetailAttachment/showDetailAttachment";
import History from "./history";
import AllDetail from "./showAllDetail/AllDetail";
import EditTitle from "./editTitle";
import { currentUserId, isAdmin } from "../../../../../utils/constants";
import { useParams } from "react-router-dom";
import { useBoardName } from "../../../../../utils/reactQuery/boards";
import commentIcon from "../../../../../styles/svg/commentIcon.svg";
import worklogIcon from "../../../../../styles/svg/worklogIcon.svg";
import historyIcon from "../../../../../styles/svg/historyIcon.svg";
import { useCard } from "../../../../../utils/reactQuery/cards";
export default function BasicGrid({
  cardId,
  description,
  title,
  data,
  setIsHovered,
}) {
  const [comment, setComment] = useState(false);
  const [worklog, setWorklog] = useState(false);
  const [history, setHistory] = useState(false);
  const [textEditor, setTextEditer] = useState(false);
  const [allDetail, setAllDetail] = useState(true);
  const [clicked, setClicked] = useState(true);
  const [editTitle, setEditTitle] = useState(false);

  const { id } = useParams();
  const { boardName } = useBoardName(id);
  const boardOwnerId = boardName?.data?.data?.responseData?.owner_user_id;

  const {
    isLoading: isCardLoading,
    card,
    isError: isCardError,
  } = useCard(cardId);

  const conditionCheck =
    isAdmin() ||
    boardOwnerId === currentUserId() ||
    card?.data?.data?.responseData?.assigned_to_user_id === currentUserId() ||
    card?.data?.data?.responseData?.reporter === currentUserId();

  const handleAllDetailClick = () => {
    setAllDetail(true);
    setComment(false);
    setHistory(false);
    setWorklog(false);
  };

  const handleWorklogClick = () => {
    setAllDetail(false);
    setComment(false);
    setHistory(false);
    setWorklog(true);
  };

  const handleCommentClick = () => {
    setAllDetail(false);
    setWorklog(false);
    setHistory(false);
    setComment(true);
  };

  const handleHistoryClick = () => {
    setAllDetail(false);
    setComment(false);
    setWorklog(false);
    setHistory(true);
  };

  const handleTextEditor = () => {
    if (
      isAdmin() ||
      boardOwnerId == currentUserId() ||
      data?.reporter == currentUserId()
    ) {
      console.log(textEditor);
      setTextEditer(true);
    }
  };
  // console.log("description", description)
  // const handleSave = ()=>{
  //   setTextEditer(false);
  // }
  // const handleCancel = ()=>{
  //   setTextEditer(false);
  // }

  // const desc = `${description}`.includes(`data-list="bullet"`) ? `${description}`.replaceAll('<ol>','<ul>').replaceAll('</ol>','</ul>') : description

  // const correctListTypes = (html) => {
  //   return html.replace(/<ol>\s*(<li[^>]*data-list="bullet"[^>]*>.*?<\/li>\s*)+<\/ol>/g, (match) => {
  //     return match.replace(/<ol>/g, '<ul>').replace(/<\/ol>/g, '</ul>');
  //   });
  // };

  console.log("helper === ", boardName?.data?.data?.responseData);
  return (
    <>
      <Box
        sx={{ flexGrow: 1 }}
        // onMouseEnter={() => setIsHovered(true)} // Set hover state
        // onMouseLeave={() => setIsHovered(false)} // Reset hover state
      >
        <Grid container spacing={2}>
          <Grid item xs={8} sx={{ height: "100vh", overflowY: "auto" }}>
            <div>
              <EditTitle cardId={cardId} title={title} data={data} />
              <div className="description">Description</div>
              {/* <div onClick={handleTextEditor}> */}

              <Description
                cardId={cardId}
                description={description}
                setTextEditer={() => setTextEditer(false)}
                isTextEditor={textEditor}
                handleTextEditor={handleTextEditor}
                accessToUpdate={conditionCheck}
              />
              {/* </div> */}

              {/* {textEditor ? (
            <>
              <Description
                cardId={cardId}
                description={description}
                setTextEditer={setTextEditer}
              />
            </>
          ) : (
            <div className="description-box" onClick={handleTextEditor}>
              <>
                {(!description) ? (
                  <span className="Add-desc-message2">
                    Add description here....
                  </span>
                ) : (
                  <div className="Add-desc-message" dangerouslySetInnerHTML={{ __html:  correctListTypes(description)}}></div>
                )}
              </>
            </div>
          )} */}
              {/* <div className="attachment-section"> */}
              {/* <div className="attachment-heading">
              <p className="attachment-para">Attachments ()</p>
            </div> */}
              {/* <div className="attachment-box"> */}
              <AttachmentCard cardId={cardId} />
              {/* </div> */}
              {/* </div> */}
              <div className="d-flex right-btns">
                <div className="activity-message">Activity</div>
                <div className="d-flex justify-content-center align-items-center activity-btn">
                  <div className="show">Show:</div>

                  <Button
                    sx={{
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "18.23px",
                      letterSpacing: "0.02em",
                      color: "#212B36",
                      border: "none",
                      opacity: "0.7",
                      backgroundColor: "#F2F4F6",
                      // transition: clicked ? "none" : "",
                      "&:hover": {
                        backgroundColor: "#F2F4F6",
                        border: "none",
                        // transition: "none !important",
                      },
                    }}
                    className={allDetail && "btn-active"}
                    variant="outlined"
                    onClick={handleAllDetailClick}>
                    All
                  </Button>

                  <Button
                    variant="outlined"
                    sx={{
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "18.23px",
                      letterSpacing: "0.02em",
                      color: "#212B36",
                      border: "none",
                      opacity: "0.7",
                      backgroundColor: "#F2F4F6",
                      "&:hover": {
                        backgroundColor: "#F2F4F6",
                        border: "none",
                      },
                      // "&:active": {
                      //   fontFamily: "DM Sans",
                      //   fontSize: "14px",
                      //   fontWeight: "700",
                      //   lineHeight: "18.23px",
                      //   letterSpacing: "0.02em",
                      //   color: "#1B3E61",
                      // },
                    }}
                    className={worklog && "btn-active"}
                    onClick={handleWorklogClick}>
                    <img className="icon" src={worklogIcon} />
                    WORKLOG
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                      fontWeight: "500",
                      // lineHeight: "18.23px",
                      letterSpacing: "0.02em",
                      color: "#212B36",
                      border: "none",
                      opacity: "0.7",
                      backgroundColor: "#F2F4F6",
                      "&:hover": {
                        backgroundColor: "#F2F4F6",
                        border: "none",
                      },
                      // "&:active": {
                      //   fontFamily: "DM Sans",
                      //   fontSize: "14px",
                      //   fontWeight: "700",
                      //   lineHeight: "18.23px",
                      //   letterSpacing: "0.02em",
                      //   color: "#1B3E61",
                      // },
                    }}
                    className={comment && "btn-active"}
                    onClick={handleCommentClick}>
                    <img className="icon" src={commentIcon} alt="" />
                    <div>COMMENTS</div>
                  </Button>
                  <Button
                    sx={{
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "18.23px",
                      letterSpacing: "0.02em",
                      color: "#212B36",
                      border: "none",
                      opacity: "0.7",
                      backgroundColor: "#F2F4F6",
                      // transition: clicked ? "none" : "",
                      "&:hover": {
                        backgroundColor: "#F2F4F6",
                        border: "none",
                        // transition: "none !important",
                      },
                    }}
                    className={history && "btn-active"}
                    variant="outlined"
                    onClick={handleHistoryClick}>
                    <img className="icon" src={historyIcon} alt="" />
                    HISTORY
                  </Button>
                </div>
              </div>
              {allDetail && (
                <div>
                  <AllDetail cardId={cardId} />
                </div>
              )}
              {comment && (
                <div>
                  <BasicTextFields cardId={cardId} />
                  <div>
                    <AutoGridNoWrap cardId={cardId} />
                  </div>
                </div>
              )}
              {worklog && (
                <div>
                  <ShowWorklog textEditor={textEditor} cardId={cardId} />
                </div>
              )}
              {history && <History cardId={cardId} />}
            </div>
          </Grid>
          <Grid item xs={4}>
            <SidebarGrid cardId={cardId} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

import React, { useEffect, useState } from "react";
import "./description.scss";
import TextEditor from "../../../textEditor/textEditor";
import toast from "react-hot-toast";
import Button from "@mui/material/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
// import { updateCard } from "../../../../../../sevices/apiCards";
import { descriptionUpdate } from "../../../../../../sevices/apiDescription";
import ReadOnlyDescription from "./ReadOnlyDescription";
import ReactQuill from "react-quill";
const Description = ({
  description,
  setTextEditer,
  cardId,
  isTextEditor,
  handleTextEditor,
  accessToUpdate,
}) => {
  const queryClient = useQueryClient();
  const [textContent, setTextContent] = useState(description);

  const { mutate: descriptionUpdateMutate } = useMutation({
    mutationFn: descriptionUpdate,
    onSuccess: (res) => {
      queryClient.invalidateQueries([`card`, cardId]);
      // setLoading(false);
      // console.log(res);
      // console.log("text......",textContent)
      //    reset();
      toast.success(res.data.message);
      // handleClose();
    },
    onError: (error) => {
      console.error("Error update cardDescription", error);
      toast.error(error.response.data.message);
      // setLoading(false);
      // handleClose();
    },
  });

  // console.log("TEXTCONTENT",textContent)

  const handleSave = () => {
    descriptionUpdateMutate({
      card_id: cardId,
      data: {
        description: textContent,
      },
    });

    setTextEditer(false);
  };
  const handleCancel = () => {
    setTextEditer(false);
  };
  useEffect(() => {
    setTextContent(description);
  }, [description]);

  return (
    <>
      {/* {isTextEditor ? ( */}
      <>
        <div className="text-editor-box">
          {" "}
          <ReactQuill
            readOnly={!accessToUpdate}
            value={textContent}
            onChange={(value) =>
              accessToUpdate
                ? setTextContent(value)
                : ""
            }
            style={{ height: "90%" }}
          />
        </div>
        <div className="text-editor-btn-div">
          <Button
            onClick={handleSave}
            disabled={!accessToUpdate}
            className="text-editor-btn1"
            variant="contained">
            <div className="text-editor-btn-content">Save</div>
          </Button>
          <Button
            onClick={handleCancel}
            className="text-editor-btn2"
            variant="outlined">
            <div className="text-editor-btn-content">Cancel</div>
          </Button>
        </div>
      </>
      {/* ) : (
        <div className="description-box" onClick={handleTextEditor}>
          <ReadOnlyDescription description={description} />
        </div>
      )} */}
    </>
  );
};

export default Description;

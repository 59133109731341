import { Button, Divider, Menu, MenuItem, MenuList } from "@mui/material";
import React, { useEffect, useState } from "react";
import DownArrow from "../../../../../../styles/svg/downArrow.svg";
import note from "../../../../../../styles/svg/note.svg";
// import SearchBoard from "./SearchBoard";
import { useSelector } from "react-redux";
// import { truncateText } from "../../../../utils/helpers";
import { useDispatch } from "react-redux";
import { truncateText } from "../../../../../../utils/helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { updateCard } from "../../../../../../sevices/apiCards";
import { useParams, useSearchParams } from "react-router-dom";
import { useBoardName, useBoardsWithSameWorkflow } from "../../../../../../utils/reactQuery/boards";


function ChangeBoard({cardId, card}) {
    const [activeBoardList,setactiveBoardList]= useState([])
    const [allChecked,setAllChecked] = useState(false)
    const [searchQuery, setSearchQuery] = useState("");
    const [currentBoardName, setCurrentBoardName] = useState('')
    // const [boardId, setBoardId] = useState('')
    // const {id} = useParams();
    const boardId = card?.data?.data?.responseData?.board_id
      // useEffect(() => {

      //   setBoardId(id)
      // }, [id]);
    
    const { boardName } = useBoardName(boardId);
    const workflow_id = boardName?.data?.data?.responseData?.workflow_id
    const {board} = useBoardsWithSameWorkflow(workflow_id)
    const boardsWithSameWorkFlow = board?.data?.data?.responseData
    console.log("boardName",card)
    // const [searchParams, setSearchParams] = useSearchParams()
    // const [selectedBoard, setSelectedBoard] = useState('');
    const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const queryClient = useQueryClient();
  // const activeBoard = useSelector(
  //   (state) => state.activeBoard.activeBoardList
  // ); 
  useEffect(() => {
    // setactiveBoardList(activeBoard);
    setCurrentBoardName(card?.data?.data?.responseData?.board?.board_name)
  }, [card]);

  // console.log("active",activeBoardList)

// const filteredItems = boardsWithSameWorkFlow?.filter((item) =>
// (item.board_name.toLowerCase() && item.is_archive == false).sort((a, b) => a.board_name.localeCompare(b.board_name)))
const filteredItems = boardsWithSameWorkFlow
  ?.filter((item) => item.board_name.toLowerCase() && item.is_archive == false)
  .sort((a, b) => a.board_name.localeCompare(b.board_name));
// console.log("CARD",card)
// const currentBoardName = card?.data?.data?.responseData?.board?.board_name


const { mutate: updateMutate } = useMutation({
    mutationFn: updateCard,
    onSuccess: (res) => {
      console.log("Mutation success:", res);
      // queryClient.invalidateQueries({
      //   queryKey: [`boards`],
      // });
      queryClient.invalidateQueries(["cards", boardId]);
      setAnchorEl(null)
    // queryClient.invalidateQueries({
    //   queryKey: [`cards`, id],
    // });
      // queryClient.invalidateQueries({
      //   queryKey: [`history`, cardId],
      // });
    //   queryClient.invalidateQueries({
    //     queryKey: [`card`, cardId],
    //   });
      
      // setLoading(false);
      toast.success(res.data.message);
      //  handleCloseDialog();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      // toast.error(error.response.data.message);
      // setLoading(false);
      //  handleCloseDialog();
    },
  });

  // const updateSearchParams = (key, value) => {
  //   const newSearchParams = new URLSearchParams(searchParams);
  //   newSearchParams.set(key, value);
  //   setSearchParams(newSearchParams);
  // };

  const selectBoard = (e, board_id)=>{

    updateMutate({
        card_id: cardId,
        data:{board_id: board_id}
    })
  }


  return (
    <div className="mr-2">
      <Button
        variant="outlined"
        color="primary"
        id="epics-board-dropdown"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        endIcon={<img src={DownArrow} />}
        startIcon={<img src={note} alt="" />}
        // className="white-button epics-btn"
        className="filter-btn white-button"
        sx={{
          height: "34px",
          "&:hover": {
            outline: "none",
            backgroundColor: "rgb(240 249 254)",
            border: "1px solid #1b3e61",
          },
        }}
      >
        {/* <img src={apicIcon} className="header-filter-icon" alt="not found" />{" "} */}
        <p className="header-action-button-text mr-1">
            {/* {`${truncateText(currentBoardName, 20)}`} */}
            {currentBoardName}
        {/* selectedBoard == '' ? 'Select Board' : `${truncateText(
                activeBoard.find((el) => el.board_id == checkedList[0])
                  ?.board_name || '',
                20
              )}` */}
        </p>
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "epics-board-dropdown",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        className="assign-board-owner-list"
      >
        {/* <MenuList onKeyDown={handleKeyDown}> */}
        {/* <div onKeyDown={(e) => e.stopPropagation()}>
        </div> */}
        {filteredItems?.map((board, idx) => {
          return (
            <MenuItem
              key={`board-list-my-task`}
              className="custom-border"
              onClick={(event) => selectBoard(event, board.board_id)}
            >
              <p className="board-name-filter">
                {truncateText(board.board_name, 26)}
              </p>
              {/* </label> */}
              {/* <Divider className="m-0" /> */}
            </MenuItem>
          );
        })}
        {/* </MenuList> */}
      </Menu>
    </div>
  );
}

export default ChangeBoard;

import React, { useRef, useState } from "react";
import { Button, Grid, Stack, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useParams, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import Users from "./Users/Users";
import attachFileIcon from "../../../../styles/svg/attachment.svg";
import AttachmentBar from "../attachments";
import Archive from "./Archive";
import BoardName from "./BoardName";
import { isAdmin } from "../../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import EpicButton from "./epicButton/EpicButton";
import { filterUser } from "../../../../redux/usersSlice";
import { searchCard, setGridView } from "../../../../redux/SearchSlice";
import gridActiveIcon from "../../../../styles/svg/grid-view-active.svg";
import gridInactiveIcon from "../../../../styles/svg/grid-view-inactive.svg";
import listActiveIcon from "../../../../styles/svg/list-view-active.svg";
import listInactiveIcon from "../../../../styles/svg/list-view-inactive.svg";
import GlobalSearch from "../../../../components/Search";
import _ from "lodash";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import MilestoneSection from "../MilestoneSection";

function BoradHeader({ boardName, boardId, ownerId }) {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueryData(["boardName", id]);
  const userData = useSelector((state) => state.users.logInUser);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isFeed, setFeed] = useState(false);
  const [attachmentDialog, setAttachmentDialog] = useState({
    right: false,
  });
  const [milestone, setMilestone] = useCustomSearchParams("milestone");

  const handleChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    debouncedSearch(value);
  };

  const debouncedSearch = useRef(
    _.debounce((value) => {
      dispatch(searchCard(value));
    }, 300) // 300ms delay
  ).current;

  const [inputValue, setInputValue] = useState("");
  const gridView = useSelector((state) => state.search.gridView);
  // const toggleDrawer = (anchor, open) => (event) => {
  //   if (
  //     event.type === "keydown" &&
  //     (event.key === "Tab" || event.key === "Shift")
  //   ) {
  //     return;
  //   }

  //   setAttachmentDialog({ ...attachmentDialog, [anchor]: open });
  // };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setAttachmentDialog((prev) => ({ ...prev, [anchor]: open }));
  };

  const removeSearchParams = (paramsToRemove) => {
    // Create a copy of the current search params
    const newSearchParams = new URLSearchParams(searchParams);

    // Remove each parameter in the paramsToRemove array
    paramsToRemove.forEach((param) => {
      newSearchParams.delete(param);
    });

    // Set the updated search params
    setSearchParams(newSearchParams);
  };
  const filterUserId = searchParams.get("search");
  const epicFilter = searchParams.get("epic");
  const clearFilters = () => {
    removeSearchParams(["search", "epic"]);
    dispatch(filterUser(null));
    dispatch(searchCard([]));
  };

  return (
    <>
      <Grid
        container
        item
        xs={12}
        className="remove-container-width fixed-ui"
        justifyContent={"center"}
        alignItems={"center"}>
        {isFeed ? (
          <p className="board-heading-name" onClick={() => setFeed(false)}>
            {`Feeds: ${boardName?.data?.responseData?.board_name}`}
          </p>
        ) : (
          <Grid item xs={3} md={5} className="d-flex align-items-center">
            {boardName && <BoardName boardName={boardName} />}
          </Grid>
        )}
        <Grid item xs={9} md={7}>
          <div className="d-flex justify-content-end">
            <Stack spacing={1} direction="row">
              {(filterUserId || epicFilter) && (
                <Button
                  size="small"
                  endIcon={<ClearIcon />}
                  className="capitalize"
                  onClick={clearFilters}>
                  Clear Filter
                </Button>
              )}
              <Users
                boardName={boardName}
                boardId={boardId}
                ownerId={ownerId}
              />
              <EpicButton boardNameDetails={boardName} />
              <Stack className="grid-list-cont">
                <img
                  src={gridView ? gridActiveIcon : gridInactiveIcon}
                  alt="gridIcon"
                  onClick={() => (gridView ? "" : dispatch(setGridView()))}
                />
                <img
                  src={gridView ? listInactiveIcon : listActiveIcon}
                  alt="listIcon"
                  onClick={() => (gridView ? dispatch(setGridView()) : "")}
                />
              </Stack>
              <Button
                variant="outlined"
                color="primary"
                className="white-button attach-btn"
                onClick={() =>
                  setAttachmentDialog({ ...attachmentDialog, right: true })
                }
                sx={{
                  height: "38px",
                  padding: "18px 5px",
                  minWidth: "35px",
                  "&:hover": {
                    outline: "none",
                    backgroundColor: "rgb(240 249 254)",
                    border: "1px solid #1b3e61",
                  },
                }}>
                <img
                  src={attachFileIcon}
                  className="header-filter-icon"
                  alt="not found"
                />
                {/* &nbsp; <p className="header-action-button-text">Attach</p> */}
              </Button>
              <GlobalSearch
                placeholder={"Search..."}
                searchText={inputValue}
                handleChange={handleChange}
              />
              <Archive
                isOwner={cachedData?.data?.data?.responseData?.is_owner}
                isAdmin={isAdmin()}
                boardId={boardId}
                handleFeed={(bool) => setFeed(bool)}
              />
            </Stack>
          </div>
        </Grid>
        {milestone && <MilestoneSection boardName={boardName} />}
      </Grid>
      {attachmentDialog && (
        <AttachmentBar state={attachmentDialog} toggleDrawer={toggleDrawer} />
      )}
    </>
  );
}

export default BoradHeader;
